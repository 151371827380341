import React, {useEffect, useState} from "react";
import Movie from "./components/Movie";

const FEATURED_API = "https://api.themoviedb.org/3/discover/movie?sort_by=popularity.desc&api_key=f3640fa30300c5e339df4fb41c5f3eed&page=1";

const SEARCH_API = "https://api.themoviedb.org/3/search/movie?&api_key=f3640fa30300c5e339df4fb41c5f3eed&query=";

function App() {
  const [movies, setMovies ] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getMovies(FEATURED_API)
  }, []);

  const getMovies = (API) => {
    fetch(API).then(res => res.json())
    .then(data => {
      console.log(data);
      setMovies(data.results);
    });
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if(searchTerm){
      getMovies(SEARCH_API + searchTerm);

      setSearchTerm('');
    }

  }

  const handleOnChange = (e) => {
    setSearchTerm(e.target.value);
  }

  return (
    <>
    <header>
      <form onSubmit={handleOnSubmit}>
      <input className="search" 
            type="text" 
            placeholder="Search... " 
            value={searchTerm}
            onChange={handleOnChange}></input>
      </form>
    
  </header>
    <div className="movie-container">
      {movies.map((movie) => 
      <Movie key={movie.id} {...movie} />)}
    </div>
    </>
  );
}

export default App;
